import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import PageHeader from '../../components/common/PageHeader';
import PricingPlans from './PricingPlans';
import ComaprePlans from './ComaprePlans';
import FaqTwo from './FaqTwo';
import CtaSubscribe from './CtaSubscribe';
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import Footer from '../../layout/Footer/Footer';


const Pricing = () => {
  const { t } = useTranslation();

  const PricingCardsMainTitle = () => {
    return (
      <div style={{ marginTop: 60, width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 6, paddingInline: 4 }
      }>
        <h1 style={{ textAlign: "center", color: "#FF8501" }}>
          {t("pages.pricing.pricingPlans.TopOfferTextTitle")}
        </h1>
        <h3 style={{ textAlign: "center", color: "#240046", fontWeight: "600", width: "100%", margin: 0 }}>
          {t('pages.pricing.pricingPlans.golderFeature01')}
        </h3>
        <div style={{ marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center", gap: 8 }}>
          <span style={{
            color: "#FF8501",
            marginBottom: 8
          }}><BsArrowRight /></span>
          < h5 style={{ textAlign: "center", color: "#FF8501", fontWeight: "600", width: "100%" }}>
            {t('pages.pricing.pricingPlans.featureZero')}
          </h5>
          <span style={{
            color: "#FF8501",
            marginBottom: 8
          }}><BsArrowLeft /></span>
        </div>
      </div >
    );
  };

  return (
    <Layout>
      <PageMeta
        title={t("tags.title")}
        description="description"
        appDesciption={t("tags.description")}
        keywords="keywords"
        appKeywords={t("tags.keywords")}
      />
      <Navbar navDark />
      <PageHeader
        title={t("pages.pricing.header.title")}
        desc={t("pages.pricing.header.desc")}
      />
      <PricingCardsMainTitle />
      <PricingPlans paddingTop='ptb-60' />
      <ComaprePlans />
      <FaqTwo />
      {/* <Testimonial bgWhite /> */}
      <CtaSubscribe />
      <Footer footerLight />
    </Layout>
  );
};

export default Pricing;
